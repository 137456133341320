import { GatsbySeo } from 'gatsby-plugin-next-seo'

import TreinoEmCasaComponent from '../components/Comercial/TreinoEmCasa'

function Page() {
  return (
    <>
      <GatsbySeo title="Treino em Casa | Decathlon" noindex nofollow />
      <TreinoEmCasaComponent />
    </>
  )
}

export default Page
